<template>
  <div>
    <vue-html2pdf
      :enable-download="true"
      :manual-pagination="true"
      pdf-content-width="1200px"
      :pdf-quality="2"
      filename="QR_Code"
      pdf-format="a3"
      ref="html2Pdf"
    >
      <section slot="pdf-content">
        <link
          rel="stylesheet"
          href="https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,700,900"
        />
        <div
          :style="`
            background: url('${template.var.qrcodeplakat}');
            background-size: cover;
            background-position: bottom center;
            width: 1110px;
            height: 1576px;
            margin-top: 10px;
            padding-top: 120px;
            padding-left: 120px;
            padding-right: 120px;
          `"
        >
          <div
            style="
              font-family: Roboto;
              color: #000000;
              text-align: center;
              width: 850px;
              padding-left: 145px;
              padding-right: 145px;
              padding-top: 125px;
              padding-bottom: 50px;
            "
          >
            <div
              style="
                position: absolute;
                margin: 230px;
                background-color: #fff;
                width: 140px;
                height: 140px;
              "
            >
              <img :src="user.data.verein.logo" style="height: 95%;" />
            </div>
            <qrcode-vue
              :value="
                sportstaette.main
                  ? template.var.app_url + '/' + user.data.alias
                  : template.var.app_url +
                    '/' +
                    user.data.alias +
                    '/' +
                    sportstaette.link
              "
              size="600"
              level="H"
            ></qrcode-vue>
            <h1
              style="
                font-size: 20px;
                align: center;
                font-weight: 700;
                margin-top: 50px;
              "
            >
              {{ template.var.app_url }}/{{
                sportstaette.main
                  ? user.data.alias
                  : user.data.alias + '/' + sportstaette.link
              }}
            </h1>
          </div>
        </div>
      </section>
    </vue-html2pdf>
    <v-row class="mt-3 pt-5">
      <v-col cols="auto" align-self="center">
        <v-btn icon to="/verein/sportstaetten" exact>
          <v-icon large>mdi-chevron-left</v-icon>
        </v-btn>
      </v-col>
      <v-col cols="auto" class="text-center" align-self="center">
        <v-icon x-large>mdi-stadium</v-icon>
      </v-col>
      <v-col align-self="center">
        <h3 class="white--text font-weight-bold">
          {{ sportstaette.name }}
        </h3>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col cols="12">
        <v-card
          :dark="!template.light"
          :light="template.light"
          class="rounded-xl pa-4"
        >
          <v-row justify="center">
            <v-col cols="12" md="10" class="py-0">
              <v-text-field
                filled
                rounded
                label="Name"
                v-model="sportstaette.name"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="10" class="py-0" v-if="!sportstaette.main">
              <v-text-field
                filled
                rounded
                label="Link"
                :prefix="`${template.var.app_url}/${user.data.alias}${
                  sportstaette.main ? '' : '/'
                }`"
                :disabled="sportstaette.main"
                v-model="sportstaette.link"
                @input="check_link()"
                :success-messages="linkok"
                :error-messages="linkexist"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="10" class="py-0" v-if="sportstaette.main">
              <v-text-field
                filled
                rounded
                label="Link"
                :prefix="`${template.var.app_url}/${user.data.alias}${
                  sportstaette.main ? '' : '/'
                }`"
                :disabled="sportstaette.main"
                value=" "
                @input="check_link()"
                :success-messages="linkok"
                :error-messages="linkexist"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="10" class="py-0">
              <v-text-field
                filled
                rounded
                type="number"
                label="Maximal zugelassene Zuschauer"
                v-model="sportstaette.maxzuschauer"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="10" class="py-0">
              <v-btn
                :color="success ? 'success' : template.colors.primary"
                block
                rounded
                large
                :loading="creating"
                @click="save()"
              >
                <v-scroll-x-transition hide-on-leave>
                  <div v-if="success">
                    <v-icon color="white" class="mr-2">mdi-check-bold</v-icon>
                    GESPEICHERT
                  </div>
                </v-scroll-x-transition>
                <v-scroll-x-reverse-transition hide-on-leave>
                  <span v-if="!success">SPEICHERN</span>
                </v-scroll-x-reverse-transition>
              </v-btn>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col cols="12">
        <v-card
          :dark="!template.light"
          :light="template.light"
          class="rounded-xl pa-4"
        >
          <v-row justify="center">
            <v-col cols="12" md="10" class="py-0">
              <h3>
                Zuschauer können sich für Veranstaltungen dieser Sportstätte
                über den oben festgelegten Link einchecken. Lade das
                nachfolgende Plakat herunter, drucke es aus und hänge es gut
                sichtbar an dieser Sportstätte auf, damit Zuschauer sich schnell
                einchecken können.
              </h3>
            </v-col>
            <v-col cols="12" md="10" class="py-0">
              <cardbutton
                title="Plakat mit QR-Code herunterladen"
                icon="mdi-file-pdf"
                :textcolor="template.colors.block_text"
                :bgcolor="template.colors.blocks"
                upper
                :loading="generating"
                @click="generatePDF()"
              />
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col cols="12">
        <v-card
          :dark="!template.light"
          :light="template.light"
          class="rounded-xl pa-4"
        >
          <v-row justify="center">
            <v-col
              cols="12"
              md="10"
              class="py-0 mt-3"
              v-if="!sportstaette.main"
            >
              <v-btn
                :color="success ? 'success' : template.colors.primary"
                block
                rounded
                outlined
                large
                :loading="creating"
                @click="deleting ? deletePlace() : (deleting = true)"
              >
                {{ deleting ? 'LÖSCHEN BESTÄTIGEN' : 'SPORTSTÄTTE LÖSCHEN' }}
              </v-btn>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
//import firebase from '../../../firebaseConfig'
import store from '../../../store'
import router from '../../../routes/index'
import cardbutton from '../../../elements/cards/card-button'
import QrcodeVue from 'qrcode.vue'
import VueHtml2pdf from 'vue-html2pdf'

export default {
  name: 'Zuschauer-erfassen',
  data() {
    return {
      sportstaette: {
        name: '',
        link: '',
        maxzuschauer: 300,
      },
      creating: false,
      generating: false,
      success: false,
      deleting: false,
      linkok: '',
      linkexist: '',
    }
  },
  components: {
    cardbutton,
    QrcodeVue,
    VueHtml2pdf,
  },
  computed: {
    ...mapGetters({
      user: 'user',
      template: 'template',
    }),
  },
  mounted() {
    if (this.$route.params.sportstaette) {
      if (this.user.data.sportstaetten) {
        var place = this.user.data.sportstaetten[
          this.$route.params.sportstaette
        ]
        this.sportstaette = {
          name: place.name,
          link: place.link,
          maxzuschauer: place.maxzuschauer,
        }
        if (place.main) {
          this.sportstaette.main = place.main
        }
        if (!this.sportstaette.link) {
          this.sportstaette.link = ''
        }
        if (!this.sportstaette.maxzuschauer) {
          this.sportstaette.maxzuschauer = 300
        }
      }
    } else {
      router.push('/verein/sportstaetten')
    }
  },
  methods: {
    parseDate(date) {
      var datum = date.split('-')
      return datum[2] + '.' + datum[1] + '.' + datum[0]
    },
    check_link() {
      this.linkexist = ''
      this.linkok = ''
      if (this.sportstaette.link.match(/(\\|\/){1,}/)) {
        this.linkexist = 'Der eingegebene Link enthält unzulässige Zeichen'
        return false
      }
      var i
      for (i = 0; i < this.user.data.sportstaetten; i++) {
        if (this.sportstaette.link == this.user.data.sportstaetten[i].link) {
          this.linkexist = 'Der eingegebene Link enthält unzulässige Zeichen'
        }
      }
      if (this.linkexist) {
        return false
      } else {
        this.linkok = 'Dieser Link-Name ist verfügbar'
        return true
      }
    },
    save() {
      this.creating = true
      var neue_sportstaetten = this.user.data.sportstaetten
      neue_sportstaetten[this.$route.params.sportstaette] = this.sportstaette
      firebase
        .firestore()
        .collection('User')
        .doc(this.user.data.uid)
        .update({
          sportstaetten: neue_sportstaetten,
        })
        .then(() => {
          this.creating = false
          this.success = true
          setTimeout(() => {
            this.success = false
          }, 4000)
        })
    },
    deletePlace() {
      var i
      var neue_sportstaetten = []
      for (i = 0; i < this.user.data.sportstaetten.length; i++) {
        if (this.$route.params.sportstaette != i) {
          neue_sportstaetten.push(this.user.data.sportstaetten[i])
        }
      }
      firebase
        .firestore()
        .collection('User')
        .doc(this.user.data.uid)
        .update({
          sportstaetten: neue_sportstaetten,
        })
        .then(() => {
          router.push('/verein/sportstaetten')
        })
    },
    generatePDF() {
      this.generating = true
      this.$refs.html2Pdf.generatePdf()
      this.$emit('generated', true)
      setTimeout(() => {
        this.generating = false
      }, 4000)
    },
  },
}
</script>
